import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'
// import config from '@/config'
// const FileSaver = require('../../node_modules/file-saver/dist/FileSaver')

const LedgerMid = ({ dataColumn, dataTable }, title) => {
  const workBook = new ExcelJS.Workbook()
  const workSheet = workBook.addWorksheet(title, {
    views: [{ state: 'frozen', xSplit: 6 }],
  })
  workBook.creator = 'Hasan Educational Technology'
  workBook.lastModifiedBy = 'Hasan Educational Technology'
  workBook.created = new Date()
  workBook.modified = workBook.created

  const columns = [
    { key: 'no', width: 5 },
    { key: 'nis', width: 12 },
    { key: 'nisn', width: 12 },
    { key: 'namaSiswa', width: 30 },
    { key: 'waliKelas', width: 20 },
    { key: 'kelas', width: 12 },
  ]

  const fixedColumn = [
    'NO',
    'NIS',
    'NISN',
    'NAMA SISWA',
    'WALI KELAS',
    'KELAS',
  ]
  const valuesRow1 = [...fixedColumn]
  const valuesRow2 = [...fixedColumn]
  const valuesRow3 = [...fixedColumn]

  const startCounterColumn = 7
  let counterColumn = 7
  const valueMergeRow2 = []
  for (let idxMapel = 0; idxMapel < dataColumn.length; idxMapel++) {
    for (
      let idxColumNilai = 0;
      idxColumNilai < dataColumn[idxMapel].children.length;
      idxColumNilai++
    ) {
      const columnNilai = dataColumn[idxMapel].children[idxColumNilai]
      valuesRow1.push('MATA PELAJARAN')
      valuesRow2.push(dataColumn[idxMapel].code)
      valuesRow3.push(columnNilai.title)
      columns.push({ key: `${columnNilai.key}`, width: 12 })
    }
    const merge = counterColumn + (dataColumn[idxMapel].children.length - 1)
    valueMergeRow2.push({ start: counterColumn, end: merge })
    counterColumn = merge + 1
  }

  const valuesrow = [valuesRow1, valuesRow2, valuesRow3]
  const additionColumn = [
    ['JML', 'RATA2', 'ABSENSI SISWA', null, null],
    [null, null, 'JUMLAH', null, null],
    [null, null, 'S', 'I', 'A'],
  ]
  for (let idxRow = 1; idxRow <= 3; idxRow++) {
    valuesrow[idxRow - 1] = [
      ...valuesrow[idxRow - 1],
      ...additionColumn[idxRow - 1],
    ]
    workSheet.getRow(idxRow).values = valuesrow[idxRow - 1]

    if (idxRow === 1) {
      workSheet.mergeCells(
        idxRow,
        startCounterColumn,
        idxRow,
        counterColumn - 1,
      )
    }

    if (idxRow === 2) {
      for (let idxMapel = 0; idxMapel < dataColumn.length; idxMapel++) {
        workSheet.mergeCells(
          idxRow,
          valueMergeRow2[idxMapel].start,
          idxRow,
          valueMergeRow2[idxMapel].end,
        )
      }
    }
  }

  columns.push(
    { key: 'jumlahNilai', width: 12 },
    { key: 'rata2Nilai', width: 12 },
    { key: 'sakit', width: 6 },
    { key: 'izin', width: 6 },
    { key: 'alfa', width: 6 },
  )

  workSheet.columns = columns

  workSheet.columns.forEach((column, i) => {
    const columnIndex = i + 1

    for (let idx = 0; idx < 3; idx++) {
      const index = idx + 1
      const cell = workSheet.getRow(index).getCell(column.key)
      cell.style = {
        font: { bold: true },
      }
      cell.fill = {
        // mode
        type: 'pattern',
        // padding
        pattern: 'solid',
        // background color
        fgColor: {
          argb: '999999',
        },
      }
    }

    if (columnIndex === 4 || columnIndex === 5) {
      column.alignment = { horizontal: 'left' }
    } else {
      column.alignment = { horizontal: 'center' }
    }
    if (
      columnIndex <= 6 ||
      column.key === 'jumlahNilai' ||
      column.key === 'rata2Nilai'
    ) {
      column.alignment.vertical = 'middle'
      workSheet.mergeCells(1, columnIndex, 3, columnIndex)
    }
    if (column.key === 'sakit') {
      workSheet.mergeCells(1, columnIndex, 1, columnIndex + 2)
      workSheet.mergeCells(2, columnIndex, 2, columnIndex + 2)
    }
  })

  dataTable.forEach((el, index) => {
    workSheet.addRow({
      ...el,
    })
  })

  const borderStyles = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }

  workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
      cell.border = borderStyles
    })
  })

  console.log(workSheet.columns, 'yeyeyeyeyeyeye')

  workBook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`))
    .catch((err) => console.log('Error writing excel export', err))
}

const LedgerFinal = ({ dataHeader, dataColumn, dataTable }, title) => {
  const workBook = new ExcelJS.Workbook()
  const workSheet = workBook.addWorksheet(title, {
    // views: [
    //   { state: 'frozen', xSplit: 6 },
    // ],
  })
  workBook.creator = 'Hasan Educational Technology'
  workBook.lastModifiedBy = 'Hasan Educational Technology'
  workBook.created = new Date()
  workBook.modified = workBook.created

  console.log('dataHeader :>> ', dataHeader)
  console.log('dataColumn :>> ', dataColumn)
  console.log('dataTable :>> ', dataTable)

  const local = JSON.parse(localStorage.getItem('institusi'))
  const header = [
    'REKAP NILAI RAPORT',
    // `SISWA ${config.school.name2.toUpperCase()}`,
    `SISWA ${local.nama.toUpperCase() || ''}`,
    `SEMESTER ${dataHeader.semester} (${
      dataHeader.semester === '1' ? 'SATU' : 'DUA'
    }) TP ${dataHeader.tahunPelajaran}`,
  ]

  for (let i = 1; i <= 3; i++) {
    workSheet.getCell(`A${i}`).value = header[i - 1]
    workSheet.getCell(`A${i}`).font = {
      name: 'Arial',
      size: 14,
      bold: true,
    }
    workSheet.mergeCells(`A${i}:P${i}`)
  }

  const startRowSubheader = 5
  const cellsSubheader = [
    {
      cell: 'A',
      isMerge: true,
      mergeTo: 'B',
      title: 'Tahun Pelajaran: ',
    },
    {
      cell: 'C',
      isMerge: false,
      title: dataHeader.tahunPelajaran,
    },
    {
      cell: 'E',
      isMerge: false,
      title: 'Kelas: ',
    },
    {
      cell: 'F',
      isMerge: false,
      title: dataHeader.kelas,
      isColor: true,
      color: 'eff704',
    },
    {
      cell: 'I',
      isMerge: false,
      title: 'Wali Kelas: ',
    },
    {
      cell: 'J',
      isMerge: true,
      mergeTo: 'L',
      title: dataHeader.waliKelas,
      isColor: true,
      color: 'eff704',
    },
    {
      cell: 'N',
      isMerge: false,
      title: 'Semester: ',
    },
    {
      cell: 'O',
      isMerge: false,
      title: dataHeader.semester,
      isColor: true,
      color: 'eff704',
    },
  ]

  cellsSubheader.forEach((el) => {
    workSheet.getCell(`${el.cell}${startRowSubheader}`).value = el.title
    if (el.isColor) {
      workSheet.getCell(`${el.cell}${startRowSubheader}`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: el.color,
        },
      }
    }
    if (el.isMerge) {
      workSheet.mergeCells(
        `${el.cell}${startRowSubheader}:${el.mergeTo}${startRowSubheader}`,
      )
    }
  })

  const columns = [
    { key: 'no', width: 5 },
    { key: 'nis', width: 12 },
    { key: 'nisn', width: 12 },
    { key: 'namaSiswa', width: 40 },
    { key: 'jenisKelamin', width: 15 },
  ]

  const fixedColumn = ['NO', 'NIS', 'NISN', 'NAMA SISWA', 'JENIS KELAMIN']
  const knowSkill = [
    { name: 'Knowledge', code: 'KNOW' },
    { name: 'Skill', code: 'SKILL' },
  ]

  const valuesRow1 = [...fixedColumn]
  const valuesRow2 = [...fixedColumn]
  const valuesRow3 = [...fixedColumn]

  const startCounterColumn = 6
  let counterColumn = 6
  const valueMergeRow2 = []
  for (let idxMapel = 0; idxMapel < dataColumn.length; idxMapel++) {
    for (
      let idxColumNilai = 0;
      idxColumNilai < knowSkill.length;
      idxColumNilai++
    ) {
      // valuesRow1.push(`GROUP ${dataColumn[idxMapel].kelompok}`)
      valuesRow1.push('MATA PELAJARAN')
      valuesRow2.push(dataColumn[idxMapel].code)
      valuesRow3.push(knowSkill[idxColumNilai].code)
      columns.push({
        key: `${dataColumn[idxMapel].code}_${knowSkill[idxColumNilai].name}_${dataColumn[idxMapel].kelompok}`,
        width: 12,
      })
    }

    const mergeRow2 = counterColumn + (knowSkill.length - 1)
    valueMergeRow2.push({ start: counterColumn, end: mergeRow2 })
    counterColumn = mergeRow2 + 1
  }

  const valuesrow = [valuesRow1, valuesRow2, valuesRow3]
  const additionColumn = [
    ['JML', 'RATA2'],
    [null, null],
    [null, null],
  ]
  for (let idxRow = 7; idxRow <= 9; idxRow++) {
    valuesrow[idxRow - 7] = [
      ...valuesrow[idxRow - 7],
      ...additionColumn[idxRow - 7],
    ]
    workSheet.getRow(idxRow).values = valuesrow[idxRow - 7]

    if (idxRow === 7) {
      workSheet.mergeCells(
        idxRow,
        startCounterColumn,
        idxRow,
        counterColumn - 1,
      )
    }

    if (idxRow === 8) {
      for (let idxMapel = 0; idxMapel < dataColumn.length; idxMapel++) {
        workSheet.mergeCells(
          idxRow,
          valueMergeRow2[idxMapel].start,
          idxRow,
          valueMergeRow2[idxMapel].end,
        )
      }
    }
  }

  columns.push(
    { key: 'jumlahNilai', width: 12 },
    { key: 'rata2Nilai', width: 12 },
  )

  workSheet.columns = columns

  workSheet.columns.forEach((column, i) => {
    const columnIndex = i + 1

    for (let idx = 6; idx < 9; idx++) {
      const index = idx + 1
      const cell = workSheet.getRow(index).getCell(column.key)
      cell.style = {
        font: { bold: true },
      }
      cell.border = borderStyles
    }

    if (columnIndex === 4) {
      column.alignment = { horizontal: 'left' }
    } else {
      column.alignment = { horizontal: 'center' }
    }
    if (
      columnIndex < 6 ||
      column.key === 'jumlahNilai' ||
      column.key === 'rata2Nilai'
    ) {
      column.alignment.vertical = 'middle'
      workSheet.mergeCells(
        startRowSubheader + 2,
        columnIndex,
        startRowSubheader + 4,
        columnIndex,
      )
    }
  })

  const greenCell = ['no', 'nis', 'nisn', 'namaSiswa', 'jenisKelamin']
  const blueDarkCell = ['jumlahNilai', 'rata2Nilai']
  const colors = [
    'f7ea00',
    'ec4646',
    'e4d3cf',
    '9e9d89',
    'ccffbd',
    'fed049',
    '007580',
    '64dfdf',
    'ff7171',
    '6e7c7c',
    '8ac4d0',
    'ffd880',
    'c7cfb7',
    '9ede73',
  ]
  const borderStyles = {
    top: { style: 'thin' },
    left: { style: 'thin' },
    bottom: { style: 'thin' },
    right: { style: 'thin' },
  }

  dataTable.forEach((el, index) => {
    workSheet.addRow({ ...el })
  })

  workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    if (rowNumber >= 7) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = borderStyles
      })
    }

    if (rowNumber === 7) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'ffab73',
          },
        }
      })

      blueDarkCell.forEach((el) => {
        row.getCell(el).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '5f939a',
          },
        }
      })
    }

    if (rowNumber === 8 || rowNumber === 9) {
      for (let idxMapel = 0; idxMapel < dataColumn.length; idxMapel++) {
        for (
          let idxColumNilai = 0;
          idxColumNilai < knowSkill.length;
          idxColumNilai++
        ) {
          row.getCell(
            `${dataColumn[idxMapel].code}_${knowSkill[idxColumNilai].name}_${dataColumn[idxMapel].kelompok}`,
          ).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: colors[idxMapel],
            },
          }
        }
      }
    }

    if (rowNumber > 9) {
      greenCell.forEach((el) => {
        row.getCell(el).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: '54e346',
          },
        }
      })
    }
  })

  workBook.xlsx
    .writeBuffer()
    .then((buffer) => FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`))
    .catch((err) => console.log('Error writing excel export', err))
}

const Form8355ExcelHomeroom = async (data, config) => {
  try {
    const { title, kelas } = config
    const workBook = new ExcelJS.Workbook()
    const workSheet = workBook.addWorksheet(kelas, {
      // views: [
      //   { state: 'frozen', xSplit: 6 },
      // ],
    })
    const institusi = JSON.parse(localStorage.getItem('institusi'))
    workBook.creator = institusi?.nama
    workBook.lastModifiedBy = institusi?.nama
    workBook.created = new Date()
    workBook.modified = workBook.created

    workSheet.getRow(1).font = {
      name: 'Arial',
      size: 10,
      bold: true,
    }

    workSheet.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    }
    workSheet.getRow(1).height = 15
    // workSheet.getRow(1).fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: {
    //     argb: '54e346',
    //   },
    // }

    workSheet.columns = [
      { key: 'no', width: 5, header: 'NO' },
      { key: 'nis', width: 12, header: 'NIS' },
      { key: 'nisn', width: 12, header: 'NISN' },
      { key: 'nama', width: 40, header: 'NAMA SISWA' },
      { key: 'jenis_kelamin', width: 5, header: 'L/P' },
      { key: 'tempat_lahir', width: 15, header: 'TEMPAT LAHIR' },
      { key: 'tanggal_lahir', width: 12, header: 'TGL. LAHIR' },
      { key: 'agama', width: 10, header: 'AGAMA' },
      { key: 'nama_orang_tua', width: 20, header: 'NAMA ORANG TUA' },
      {
        key: 'alamat',
        width: 40,
        header: 'ALAMAT',
      },
      { key: 'nomor_seri_ijazah', width: 30, header: 'NO/TAHUN IJAZAH' },
      { key: 'nomor_seri_skhus', width: 25, header: 'NO. PESERTA SKHUN' },
    ]

    for (let i = 0; i < data.length; i++) {
      const dataMurid = data[i]
      workSheet.addRow(dataMurid)
      workSheet.getRow(dataMurid.no + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
    }

    // const greenCell = ['no', 'nis', 'nisn', 'namaSiswa', 'jenisKelamin']
    // const blueDarkCell = ['jumlahNilai', 'rata2Nilai']
    // const colors = [
    //   'f7ea00',
    //   'ec4646',
    //   'e4d3cf',
    //   '9e9d89',
    //   'ccffbd',
    // ]

    const borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }
    workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.height = 42.5
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = borderStyles
        if (colNumber === 10) {
          cell.alignment = {
            ...cell.alignment,
            wrapText: true,
          }
        }
        if (rowNumber === 1) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '999999',
            },
          }
        }
      })
    })

    const buffer = await workBook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`)
    return new Promise((resolve) => resolve())
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

const exportDataPpdbMurid = async (data, config) => {
  try {
    const { title } = config
    const workBook = new ExcelJS.Workbook()
    workBook.created = new Date()
    workBook.modified = workBook.created
    const workSheet = workBook.addWorksheet('DATA', {
      // views: [
      //   { state: 'frozen', xSplit: 6 },
      // ],
    })
    const columns = [
      { key: 'no', width: 5, header: 'No.' },
      { key: 'nama', width: 40, header: 'Nama' },
      { key: 'nis', width: 15, header: 'NIPD' },
      { key: 'jenis_kelamin', width: 5, header: 'JK' },
      { key: 'nisn', width: 15, header: 'NISN' },
      { key: 'tempat', width: 15, header: 'Tempat Lahir' },
      { key: 'tanggal', width: 12, header: 'Tanggal Lahir' },
      { key: 'nik_kitas', width: 20, header: 'NIK' },
      { key: 'agama', width: 10, header: 'Agama' },
      // alamat
      { key: 'jalan', width: 40, header: 'Alamat' },
      { key: 'rt', width: 10, header: 'RT' },
      { key: 'rw', width: 10, header: 'RW' },
      { key: 'dusun', width: 20, header: 'Dusun' },
      { key: 'kelurahan_desa', width: 20, header: 'Kelurahan' },
      { key: 'kecamatan', width: 20, header: 'Kecamatan' },
      { key: 'kode_pos', width: 15, header: 'Kode Pos' },
      //
      { key: 'tempat_tinggal', width: 20, header: 'Jenis Tinggal' },
      { key: 'moda_transportasi', width: 20, header: 'Alat Transportasi' },
      { key: 'nomor_telepon', width: 20, header: 'Telepon' },
      { key: 'nomor_hp', width: 20, header: 'HP' },
      { key: 'email', width: 30, header: 'E-Mail' },
      { key: 'nomor_seri_skhus', width: 25, header: 'SKHUN' },
      // kps_pkh
      { key: 'is_kps_pkh', width: 15, header: 'Penerima KPS' },
      { key: 'kps_pkh', width: 20, header: 'No. KPS' },
      //
      { key: 'nama_ayah', width: 40, header: 'Nama', mergeTitle: 'Data Ayah' },
      { key: 'tahun_lahir_ayah', width: 15, header: 'Tahun Lahir', mergeTitle: 'Data Ayah' },
      { key: 'pendidikan_terakhir_ayah', width: 20, header: 'Jenjang Pendidikan', mergeTitle: 'Data Ayah' },
      { key: 'pekerjaan_ayah', width: 20, header: 'Pekerjaan', mergeTitle: 'Data Ayah' },
      { key: 'penghasilan_bulanan_ayah', width: 20, header: 'Penghasilan', mergeTitle: 'Data Ayah' },
      { key: 'nama_ibu', width: 40, header: 'Nama', mergeTitle: 'Data Ibu' },
      { key: 'tahun_lahir_ibu', width: 15, header: 'Tahun Lahir', mergeTitle: 'Data Ibu' },
      { key: 'pendidikan_terakhir_ibu', width: 20, header: 'Jenjang Pendidikan', mergeTitle: 'Data Ibu' },
      { key: 'pekerjaan_ibu', width: 20, header: 'Pekerjaan', mergeTitle: 'Data Ibu' },
      { key: 'penghasilan_bulanan_ibu', width: 20, header: 'Penghasilan', mergeTitle: 'Data Ibu' },
      { key: 'nama_wali', width: 40, header: 'Nama', mergeTitle: 'Data Wali' },
      { key: 'tahun_lahir_wali', width: 15, header: 'Tahun Lahir', mergeTitle: 'Data Wali' },
      { key: 'pendidikan_terakhir_wali', width: 20, header: 'Jenjang Pendidikan', mergeTitle: 'Data Wali' },
      { key: 'pekerjaan_wali', width: 20, header: 'Pekerjaan', mergeTitle: 'Data Wali' },
      { key: 'penghasilan_bulanan_wali', width: 20, header: 'Penghasilan', mergeTitle: 'Data Wali' },
      { key: 'nomor_peserta_ujian', width: 30, header: 'No Peserta Ujian Nasional' },
      { key: 'nomor_seri_ijazah', width: 30, header: 'No Seri Ijazah' },
      // KIP
      { key: 'is_kip', width: 15, header: 'Penerima KIP' },
      { key: 'nomor_kip', width: 20, header: 'No KIP' },
      { key: 'nama_kip', width: 40, header: 'Nama KIP' },
      //
      { key: 'nomor_kks', width: 20, header: 'No KKS' },
      { key: 'akta_lahir', width: 30, header: 'No Registrasi Akta Lahir' },
      // PIP
      { key: 'is_pip', width: 15, header: 'Layak PIP (usulan dari sekolah)' },
      { key: 'alasan_pip', width: 25, header: 'Alasan Layak PIP' },
      //
      { key: 'berkebutuhan_khusus', width: 20, header: 'Kebutuhan Khusus' },
      { key: 'asal_sekolah', width: 30, header: 'Sekolah Asal' },
      { key: 'anak_ke', width: 15, header: 'Anak Ke Berapa' },
      // geolokasi_tempat_tinggal
      { key: 'latitude', width: 20, header: 'Lintang' },
      { key: 'longitude', width: 20, header: 'Bujur' },
      //
      { key: 'nomor_kk', width: 25, header: 'No KK' },
      { key: 'berat_badan', width: 15, header: 'Berat Badan (kg)' },
      { key: 'tinggi_badan', width: 15, header: 'Tinggi Badan (cm)' },
      { key: 'jumlah_saudara_kandung', width: 15, header: 'Jumlah Saudara Kandung' },
      { key: 'jarak', width: 15, header: 'Jarak Rumah Ke Sekolah (km)' },
      { key: 'tanggal_pengisian_dapodik', width: 15, header: 'Tanggal Pengisian Dapodik' },
    ]

    // workSheet.getRow(2).values = columns.map(col => col.header)
    // console.log(subCol, headColumns, columns)
    // set columns key
    workSheet.columns = columns
    workSheet.getRow(1).values = [] // so that row 1 had no value
    //
    const mergeData = {}
    // define which one nested column or not
    for (let i = 0; i < columns.length; i++) {
      const dataCol = columns[i]
      const row = workSheet.getRow(1)
      if (dataCol.mergeTitle) {
        workSheet.getRow(2).getCell(i + 1).value = dataCol.header
        const title = dataCol.mergeTitle
        if (mergeData[title]) {
          mergeData[title].end++
        } else {
          mergeData[title] = { start: i + 1, end: i + 1 }
        }
      } else {
        workSheet.mergeCells(1, i + 1, 2, i + 1)
        row.getCell(i + 1).value = dataCol.header
      }
    }
    // merge nested header with only merge its col on the same row
    for (const title in mergeData) {
      const { start, end } = mergeData[title]
      workSheet.mergeCells(1, start, 1, end)
      workSheet.getRow(1).getCell(start).value = title
    }

    // add data and alignment
    for (let i = 0; i < data.length; i++) {
      workSheet.getRow(i + 3).values = {
        ...data[i],
        no: i + 1,
      }
      workSheet.getRow(i + 3).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      }
    }

    // alignment header
    workSheet.getRow(1).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    }
    workSheet.getRow(2).alignment = {
      vertical: 'middle',
      horizontal: 'center',
    }
    //

    const borderStyles = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }

    // alignment dataTable and its style
    workSheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
      row.height = 42.5
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        cell.border = borderStyles
        if ([10, 14, 15, 47, 50, 55, 56, 57, 58, 59].includes(colNumber)) {
          cell.alignment = {
            ...cell.alignment,
            wrapText: true,
          }
        }
        if ([1, 2].includes(rowNumber)) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {
              argb: '999999',
            },
          }
        }
      })
    })

    // save
    const buffer = await workBook.xlsx.writeBuffer()
    FileSaver.saveAs(new Blob([buffer]), `${title}.xlsx`)
    return new Promise((resolve, reject) => resolve())
  } catch (err) {
    return new Promise((resolve, reject) => reject(err))
  }
}

export { LedgerMid, LedgerFinal, Form8355ExcelHomeroom, exportDataPpdbMurid }
